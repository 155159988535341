import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AuthService } from "src/app/@services/auth.service";
import { StorageService } from "src/app/@services/storage.service";
import Swal from "sweetalert2";
import {
  emailValidator,
  matchingPasswords,
} from "../../theme/utils/app-validators";
@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
  loginForm: UntypedFormGroup;
  registerForm: UntypedFormGroup;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public snackBar: MatSnackBar,
    private auth: AuthService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    if (this.auth.loggedIn()) {
      this.router.navigate(["/"]);
    }
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required, emailValidator])],
      password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });

    this.registerForm = this.formBuilder.group(
      {
        name: [
          "",
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ],
        email: ["", Validators.compose([Validators.required, emailValidator])],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      },
      { validator: matchingPasswords("password", "confirmPassword") }
    );
  }

  public onLoginFormSubmit(values: Object): void {
    if (this.loginForm.valid) {
      this.auth.login(values).then(
        (res: any) => {
          if (
            res.user.user_role != 1 && res.user.user_role != 5 && res.user.user_role != 6
          ) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Access denied",
              showConfirmButton: true,
              timer: 1500,
            });
            localStorage.clear();
          } else {
            this.afterLogin(res);
          }
        },
        (error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Email or password incorrect ",
            showConfirmButton: true,
            timer: 1500,
          });
        }
      );
    }
  }

  afterLogin(res) {
    this.storage.set("auth", res);
    this.router.navigate(["/"]);
  }

  public onRegisterFormSubmit(values: Object): void {
    if (this.registerForm.valid) {
      this.snackBar.open("You registered successfully!", "×", {
        panelClass: "success",
        verticalPosition: "top",
        duration: 3000,
      });
    }
  }
}
