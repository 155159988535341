

<div style="width: 100vw; height: 100vh;" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px">
  <mat-card class="example-card" fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)">
    &nbsp;
    <div align="center">
          <img class="logo" src="../../../assets/RR Logo 600x.png" alt="">
      </div>
      &nbsp;
      <mat-card-content>

        <form [formGroup]="loginForm">
          <div fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
          <mat-form-field appearance="outline" class="w-100 mt-2">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" formControlName="email" required>
              <mat-error *ngIf="loginForm.controls.email.errors?.required">Email is required</mat-error>
              <mat-error *ngIf="loginForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
          </mat-form-field>
        </div>
          <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
          <mat-form-field appearance="outline" class="w-100 mt-1">
              <mat-label>Password</mat-label>
              <input type="password" matInput placeholder="Password" formControlName="password" required>
              <mat-error *ngIf="loginForm.controls.password.errors?.required">Password is required</mat-error>
              <mat-error *ngIf="loginForm.controls.password.hasError('minlength')">Password isn't long enough, minimum of 6 characters</mat-error>
          </mat-form-field>
        </div>
          <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
          <div class="text-center mt-2">
              <button mat-raised-button color="primary" class="mat-elevation-z6" (click)="onLoginFormSubmit(loginForm.value)">
                  <!-- <mat-icon>exit_to_app</mat-icon> -->
                  Sign In
              </button>
            </div>
            </div>
          </div>
      </form>


      </mat-card-content>

  </mat-card>
</div>
