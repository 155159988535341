import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './admin/sign-in/sign-in.component';

import { AuthGuard } from './@guards/auth.guard';

export const routes: Routes = [

    { path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard] },

    { path: "sign-in", component: SignInComponent },
    { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },

    // { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    useHash: true,
    initialNavigation: 'enabledBlocking'
})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
