// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.



export const environmentImage = {
  production: true,
  s3:
  {
    // accessKeyId: 'AKIAXTCZPD3O7LKG7JNL',
    // secretAccessKey: '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',
    // region: 'ap-southeast-1'


    accessKeyId: 'AKIAXTCZPD3O2DDVQME6',
    secretAccessKey: '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',
    region: 'ap-southeast-1'

  },

  AWS_BUCKET:'images.rizmanruzaini.net',
  AWS_IMAGE_URL:'https://images.rizmanruzaini.net/'
};
